.dropdown-check-list {
  display: inline-block;
  width: 100%;
}

.dropdown-check-list .anchor {
  position: relative;
  cursor: pointer;
  display: inline-block;
  padding: 5px 50px 5px 10px;
  border: 1px solid #dadada;
  border-radius: 8px;
  height: 40px;
  width: 100%;
}

.dropdown-check-list .anchor:after {
  position: absolute;
  content: "";
  border-left: 2px solid black;
  border-top: 2px solid black;
  padding: 3px;
  right: 15px;
  top: 35%;
  -moz-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.dropdown-check-list .anchor:active:after {
  right: 8px;
  top: 21%;
}

.dropdown-check-list ul.items {
  padding: 2px;
  display: none;
  margin: 0;
  border: 1px solid #ccc;
  border-top: none;
  overflow: auto;
  max-height: 230px;
}

.dropdown-check-list ul.items li {
  list-style: none;
}

/* .dropdown-check-list.visible .anchor {
  color: #0094ff;
} */

.dropdown-check-list.visible .items {
  display: block;
  z-index: 100000;
}
